let $newsletterContainer;
let $newsletterFormGroup;
let $newsletterEmailField;
let $newsletterCheckboxField;
let $newsletterSubmitButton;
let newsletterSubscriptionUrl;

$(document).ready(function () {
    $newsletterContainer = $('.footer__newsletter');
    $newsletterFormGroup = $newsletterContainer.find('> .form-group');
    $newsletterEmailField = $newsletterContainer.find('#subscribe');
    $newsletterCheckboxField = $newsletterContainer.find('#gridCheck');
    $newsletterSubmitButton = $newsletterContainer.find('.footer__submit');
    newsletterSubscriptionUrl = $newsletterContainer.attr('data-target-url');
    
    $newsletterSubmitButton.click(validateFormAndSubscribe);
});

function validateFormAndSubscribe() {
    let emailAddress = $newsletterEmailField.val();
    if (emailAddress && validateEmail(emailAddress)) {
        $newsletterEmailField.removeClass('is-invalid');
        $("#invalid-feedback-newsletter").removeClass("d-block").html("");

        if ($newsletterCheckboxField.is(':checked')) {
            $newsletterCheckboxField.removeClass('is-invalid');

            $newsletterSubmitButton.prop('disabled', true);
            $newsletterFormGroup.addClass('loading');

            $.get(
                newsletterSubscriptionUrl,
                {
                    "email_address": emailAddress
                },
                showSubscriptionResult,
                'json'
            );
            if (window.connectif && window.connectif.managed && window.connectif.managed.isInitialized()){
                window.connectif.managed.sendEvents([],
                    {
                      entityInfo: {
                        primaryKey: emailAddress || ''
                      }
                    }
                );
            }
        } else {
            $newsletterCheckboxField.addClass('is-invalid');
            $("#invalid-feedback-newsletter").addClass("d-block").html(privacyPolicyMandatory);
        }

    } else {
        $newsletterEmailField.addClass('is-invalid');
        $("#invalid-feedback-newsletter").addClass("d-block").html(emailAddress ? privacyPolicyEmailInvalid : privacyPolicyEmailMandatory);
    }
}

function showSubscriptionResult(data) {
    $newsletterSubmitButton.prop('disabled', false);
    $newsletterFormGroup.removeClass('loading');

    if (data.hasError) {
        $newsletterContainer.find('.positive').hide();
        $newsletterContainer.find('.subscriberExist').hide();
        $newsletterContainer.find('.negative').show();
    } else if(data['subscriberExist']) {
        $newsletterContainer.find('.positive').hide();
        $newsletterContainer.find('.negative').hide();
        $newsletterContainer.find('.subscriberExist').show();
    }else {
        $newsletterContainer.find('.positive').show();
        $newsletterContainer.find('.negative').hide();
        $newsletterContainer.find('.subscriberExist').hide();
    }
}

function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}